import styled from "styled-components"
import React, { useState, useContext, ReactElement } from "react"
import { CartContext } from "../contexts/CartContext"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js/pure"
import {CheckoutForm} from "./CheckoutForm"
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

`

const CartData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  z-index: 8;
`

const CartItem = styled.div`
  display: flex;
  flex-direction: row;
  font-family: Helvetica, Arial, sans-serif;
  z-index: 8;
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    height: 10vh;
    width: 100%;
    @media only screen and (max-height: 500px){
      height:20vh;
    }
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    height: 10vh;
    width: 100%;
  }
  // @media screen and (orientation: landscape) {
  //   height: 30vh;
  //   width: 100%;
  // }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    height: 33vh;
    width: 100%;
    @media screen and (orientation: portrait) {
      height: 15vh;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    height: 20vh;
    width: 100%;
    @media screen and (orientation: portrait) {
      height: 15vh;
    }
  }

  @media only screen and (min-width: 1100px) {
    height: 17vh;
    width: 100%;
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    height: 18vh;
    width: 100%;
  }
  @media only screen and (min-width: 1300px) {
    height: 20vh;
    width: 100%;
  }
  @media only screen and (min-width: 1400px) {
    height: 22vh;
    width: 100%;
  }
  @media only screen and (min-width: 1600px) {
    height: 24vh;
    width: 100%;
  }
  @media only screen and (min-width: 1700px) {
    height: 26vh;
    width: 100%;
  }
  @media only screen and (min-width: 1700px) {
    height: 27vh;
    width: 100%;
  }
  @media only screen and (min-width: 1800px) {
    height: 28vh;
    width: 100%;
  }
  @media only screen and (min-width: 2000px) {
    height: 29vh;
    width: 100%;
  }
`

const Thumbnail = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    width: 20%;
    height: 10vh;
    @media only screen and (max-height: 500px){
      height:20vh;
    }
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    width: 20%;
    height: 10vh;
  }
  // @media screen and (orientation: landscape) {
  //   width: 20%;
  //   height: 30vh;
  // }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    width: 20%;
    height: 27vh;
    @media screen and (orientation: portrait) {
      height: 15vh;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    width: 20%;
    height: 16vh;
    @media screen and (orientation: portrait) {
      height: 15vh;
    }
  }

  @media only screen and (min-width: 1100px) {
    width: 20%;
    height: 17vh;
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    width: 20%;
    height: 18vh;
  }
  @media only screen and (min-width: 1300px) {
    height: 20vh;
    width: 20%;
  }
  @media only screen and (min-width: 1400px) {
    height: 22vh;
    width: 20%;
  }
  @media only screen and (min-width: 1600px) {
    height: 24vh;
    width: 23%;
  }
  @media only screen and (min-width: 1700px) {
    height: 26vh;
    width: 25%;
  }
  @media only screen and (min-width: 1800px) {
    height: 28vh;
    width: 27%;
  }
  @media only screen and (min-width: 1800px) {
    height: 29vh;
    width: 27%;
  }
`
const Name = styled.div`
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
`
const Price = styled.div`
  display: flex;
  width: 15%;
  justify-content: center;
  align-items: center;
`
const ErrorAndTotalBar = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 1.5;
`
const ErrorBar = styled.div`
  width: 80%;
  min-width: 80%;
`

const TotalBox = styled.div`
  width: 20%;
  font-family: Helvetica, Arial, sans-serif;
`

const CloseBox = styled.div`
  margin: auto;
  width: 15%;
  height: 50px;
  cursor: pointer;
`

const LeftRight = styled.div`
  height: 4px;
  width: 50px;
  position: absolute;
  margin-top: 24px;
  background-color: #f4a259;
  border-radius: 2px;
  transform: rotate(45deg);
  transition: all 0.3s ease-in;
  ${CloseBox}:hover & {
    // transform: rotate(-45deg);
    background-color: #f25c66;
  }
`

const RightLeft = styled.div`
  height: 4px;
  width: 50px;
  position: absolute;
  margin-top: 24px;
  background-color: #f4a259;
  border-radius: 2px;
  transform: rotate(-45deg);
  transition: all 0.3s ease-in;
  ${CloseBox}:hover & {
    // transform: rotate(-45deg);
    background-color: #f25c66;
  }
`

const CheckoutButton = styled.input`
background-color:rgba(0,0,0,0);
color:black;
height: 50px;
font-size: xx-large;
border-radius:5px;
border:0px;

&:hover {
  color:rgba(0,0,0,0.5)
}
&:active {
  outline-style:none;
}
`;



const CartModalLayout = (): ReactElement => {
  const [showCheckout, toggleCheckout] = useState(false);
  const [checkoutCompleted, toggleCheckoutCompleted] = useState(false);
  const { cartItems, removeProduct, total, clearCart } = useContext(CartContext)
  const stripePromise = loadStripe(
    'pk_live_51IHfRRFQJkRJBlvaaG09Qf7BeOVrly2xlwalaV0wdjWFfVKmJAam5kBqBoaT4j8LG2SsawA9tIQ301AFLW7JKYCE00AVNo6tpE'
  )
  const handleSubmit = e => {
    e.preventDefault()
    toggleCheckout(true)
  }
  // console.log(cartItems)
  return (
    <Wrapper>
      <CartData>
        {cartItems.map((item, ind) => {
          return (
            <CartItem key={ind}>
              <Thumbnail>
                <img
                  src={item.images[0].url}
                  alt={""}
                  style={{
                    height: "auto",
                    width: "10vw",
                    objectFit: "contain",
                  }}
                />
              </Thumbnail>
              <Name>{item.masterName ? item.masterName + '-':''}{item.name}{item.attributes?.reduce((acc,attribute)=>{return acc+ '-'+ attribute.value},'' )}</Name>

              <Price>{item.price}</Price>
              <CloseBox
                onClick={e => {
                  e.preventDefault()
                  removeProduct(ind)
                }}
              >
                <LeftRight />
                <RightLeft />
              </CloseBox>
            </CartItem>
          )
        })}
      </CartData>
      <ErrorAndTotalBar>
        {" "}
        <ErrorBar style={{ color: "red" }}></ErrorBar>
        <TotalBox>{"Total:" + total}</TotalBox>
      </ErrorAndTotalBar>
      {showCheckout && cartItems.length !== 0 ? (
        <Elements stripe={stripePromise}>
          <CheckoutForm toggleCheckoutCompleted={toggleCheckoutCompleted} clearCart={clearCart}/>
        </Elements>
      ) : (
        <div style={{ width: "100%", zIndex: 9 }}>
          <form
            onSubmit={handleSubmit}
            style={{ width: "100%", height: "70px" }}
          >
            <CheckoutButton
              type={"submit"}
              value={"Checkout"}
              style={{
                width: "100%",
                height: "100%",
                cursor: "pointer",
                zIndex: 10,
              }}
            ></CheckoutButton>
          </form>
        </div>
      )}
      <div>{(checkoutCompleted?"Thanks for your purchase!":"")}</div>
    </Wrapper>
  )
}

export default CartModalLayout
