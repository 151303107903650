import React, { ReactElement, useContext } from "react"
import { CartContext } from "../contexts/CartContext"
import styled from 'styled-components';

const Price = styled.div`
font-size: 20px;
padding-top: 10px;
min-height: 50px;
font-family: 'Open Sans', sans-serif;
letter-spacing: -2px;
`;

const SingleItem = ({ item, whichButton }: singleItemProps): ReactElement => {
  const { addProduct, closeModal } = useContext(CartContext)
  return (
    <div>
      <Price
        style={{
          fontSize: 30,
          paddingTop: 10,
          minHeight: 50,
          fontFamily: 'Open Sans, sans-serif',
          fontStretch: "condensed"
        }}
      >
        {!item || item.price === null
          ? ""
          : "$"+item.price}
      </Price>
      <form
        onSubmit={e => {
          e.preventDefault()
          addProduct(item)
          closeModal(e)
        }}
      >
        {whichButton(item.sku)}
      </form>
    </div>
  )
}

export default SingleItem
