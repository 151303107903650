import styled from "styled-components"
import Modal, { BaseModalBackground } from "styled-react-modal"
import React, { ReactElement, useContext, useState } from "react"
import { CartContext } from "../contexts/CartContext"

const StyledModal = Modal.styled`
display: ${props => props.style.display};
background-color: ${props =>
  props.style.backgroundColors[Math.floor(Math.random() * 3)]};
box-shadow: ${props => props.style.boxShadow};
// border:${props => props.style.border}
border-radius: ${props => props.style.borderRadius};
padding: ${props => props.style.padding};
padding-bottom: ${props => props.style.paddingBottom}
opacity: ${props => props.opacity};
transform: ${props => props.modalTransform};
transition: ${props => props.style.transition};
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  width: ${props => props.style.maxWidth600.width};
  height: ${props => props.style.maxWidth600.height};
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  width:${props => props.style.minWidth600.width};
  height:${props => props.style.minWidth600.height};
}
// @media screen and (orientation:landscape) {
//   width:${props => props.style.orientationLandscape.width};
//   height:${props => props.style.orientationLandscape.height};
// }

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  width:${props => props.style.minWidth768.width};
  height:${props => props.style.minWidth768.height};
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  width:${props => props.style.minWidth992.width};
  height:${props => props.style.minWidth992.height};
}

@media only screen and (min-width: 1100px) {
  width:${props => props.style.minWidth1100.width};
  height:${props => props.style.minWidth1100.height};
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  width:${props => props.style.minWidth1200.width};
  height:${props => props.style.minWidth1200.height};
}
@media only screen and (min-width: 1300px) {
  width:${props => props.style.minWidth1300.width};
  height:${props => props.style.minWidth1300.height};
}
@media only screen and (min-width: 1400px) {
  width:${props => props.style.minWidth1400.width};
  height:${props => props.style.minWidth1400.height};
}
@media only screen and (min-width: 1600px) {
  width:${props => props.style.minWidth1600.width};
  height:${props => props.style.minWidth1600.height};
}
@media only screen and (min-width: 1700px) {
  width:${props => props.style.minWidth1700.width};
  height:${props => props.style.minWidth1700.height};
}
@media only screen and (min-width: 1800px) {
  width:${props => props.style.minWidth1800.width};
  height:${props => props.style.minWidth1800.height};
}
@media only screen and (min-width: 2000px) {
  width:${props => props.style.minWidth2000.width};
  height:${props => props.style.minWidth2000.height};
}
`

export const FadingBackground = styled(BaseModalBackground)`
  display: flex;
  position: fixed;
  margin:8px;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 30;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  opacity: ${props => props.opacity};
  // transition: opacity linear 250ms;
  transition: all 0.2s cubic-bezier(0.25, 0.5, 0.5, 0.9);
`

interface ItemModalProps {
  children: any
  isOpen: boolean
  beforeTransform: string
  appearTransform: string
  disappearTransform: string
  style: any
}

const ModalWrapper = ({
  children,
  isOpen,
  beforeTransform,
  appearTransform,
  disappearTransform,
  style,
}: ItemModalProps): ReactElement<typeof StyledModal> => {
  const { closeModal } = useContext(CartContext)
  const [opacity, setOpacity] = useState("0")
  const [modalTransform, setTransform] = useState("")
  function beforeOpen() {
    setTransform(beforeTransform)
    setOpacity("0")
    setTimeout(() => {
      setTransform(appearTransform)
      setOpacity("1")
    }, 1)
  }

  function beforeClose() {
    return new Promise(resolve => {
      setOpacity("0")
      setTransform(disappearTransform)
      setTimeout(resolve, 200)
    })
  }
  function afterClose() {
    setTransform(beforeTransform)
  }

  return (
    <StyledModal
      isOpen={isOpen}
      allowScroll={false}
      onBackgroundClick={closeModal}
      onEscapeKeydown={closeModal}
      beforeOpen={beforeOpen}
      beforeClose={beforeClose}
      opacity={opacity}
      style={style}
      modalTransform={modalTransform}
      afterClose={afterClose}
      backgroundProps={{ opacity }}
    >
      {children}
    </StyledModal>
  )
}

export default ModalWrapper
