import React, { useRef, useContext, ReactElement } from "react"

import styled from "styled-components"
import Reel from "./Reel"
import { CartContext } from "../contexts/CartContext"
import MultiItem from "./MultiItem"
import SingleItem from "./SingleItem"

import "../fonts/font.css"

const ItemData = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`

const Button = styled.input`
background-color:rgba(0,0,0,0);
color:black;
height: 50px;
font-size: large;
border-radius:5px;
border:0px;
&:hover {
  color:rgba(0,0,0,0.5)
}
&:active {
  outline-style:none;
}
`;

const ItemName = styled.div`
font-family: Canossa Bold;
font-size: x-large;
letter-spacing:6px;
`;

const ItemModalLayout = ({
  name,
  variants,
  components,
}: itemQueryResults): ReactElement<typeof ItemData> => {
  const productName = useRef(name)
  const { addProduct, cartItems, closeModal } = useContext(CartContext)
  const foundComponent = components.find(components => components.name === 'Description');
  const productDescription = useRef(
    components !== undefined && foundComponent && foundComponent.content && foundComponent.content.paragraphs &&
      foundComponent.content
        .paragraphs[0].body.plainText
  )
  const images = variants.reduce(
    (acc, cur: variant) => acc.concat(cur.images),
    []
  )

  const isAvailable = (sku: string) => {
    const item = lookUpItem(sku)
    return item.stock > 0 && countAlreadyInCart(sku) < item.stock
  }
  const lookUpItem = (sku: string): variant => {
    return variants.find((variant: variant) => {
      return variant.sku === sku
    })
  }
  const countAlreadyInCart = (sku: string) => {
    return cartItems.filter((item: variant) => {
      return item.sku === sku
    }).length
  }
  const whichButton = (sku: string) => {
    const item = lookUpItem(sku)
    if (!item) {
      return
    }
    if (
      item.attributes &&
      item.attributes[0] &&
      item.attributes[0]["attribute"] === "inquire"
    ) {
      return (
        <Button
          type={"submit"}
          value={"Inquire"}
          style={{ pointerEvents: "none" }}
        ></Button>
      )
    } else if (isAvailable(sku) && item.price) {
      return <Button type={"submit"} value={"Add To Cart"} style={{cursor:"pointer"}}></Button>
    } else {
      return (
        <Button
          type={"submit"}
          value={"Out Of Stock"}
          style={{ pointerEvents: "none" }}
        ></Button>
      )
    }
  }
  return (
    <ItemData>
      {<Reel photosData={images} />}
      <ItemName>
        {productName.current}
      </ItemName>
      <br />

      <div>
        {productDescription.current.map((paragraph: string, ind: number) => {
          return (
            <div
              key={ind}
              style={{
                lineHeight: 1.0,
                minHeight: "1.0em",
                fontFamily: "Arial, Helvetica, sans-serif",
                fontStyle: "normal",
                fontSize: "1em",
              }}
            >
              {paragraph}{" "}
            </div>
          )
        })}
      </div>
      <div>
        {variants.length > 1 ? (
          <MultiItem
            name={name}
            variants={variants}
            addProduct={addProduct}
            whichButton={whichButton}
            closeModal={closeModal}
            lookUpItem={lookUpItem}
          />
        ) : (
          <SingleItem
            item={variants[0]}
            addProduct={addProduct}
            whichButton={whichButton}
            closeModal={closeModal}
          />
        )}
      </div>
    </ItemData>
  )
}

export default ItemModalLayout
