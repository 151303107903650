import { CardElement } from "@stripe/react-stripe-js"
import React from "react"
import "core-js/es6/promise"
import "core-js/es6/set"
import "core-js/es6/map"
import styled from "styled-components";
const CardContainer = styled.div`
border-radius:4px;
width:100%;
border-width:1px;
border-style:solid;
border-color:black;
`
const Error = styled.div`
  color: red;
`
const CreditCardForm = ({ checkoutMessage, setCheckoutMessage }) => {
  const handleCardChange = async event => {
    setCheckoutMessage(event.error ? event.error.message : "")
  }
  return (
    <div>
          <Error>{checkoutMessage}</Error>
    <CardContainer>
      <CardElement
        options={{
          style: {
            base: {
              fontSize: "16px",
              backgroundColor: "#f6f2ee",
              color: "black",
              "::placeholder": {
                color: "black",
              },
            },
            invalid: {
              color: "#9e2146",
            },
          },
        }}
        onChange={handleCardChange}
      />
      
    </CardContainer>

    </div>
  )
}

export default CreditCardForm
