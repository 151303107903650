import React, { useRef, useContext, ReactElement } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Cell from "./Cell"
import styled from "styled-components"
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  useLazyQuery,
} from "@apollo/client"
import ItemModalLayout from "./ItemModalLayout"
import CartModalLayout from "./CartModalLayout"
import { ModalProvider } from "styled-react-modal"
import ModalWrapper, { FadingBackground } from "./ModalWrapper"
import { itemModalQuery } from "../queries/itemQuery"
import { CartContext } from "../contexts/CartContext"
// require("isomorphic-fetch")
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: "https://api.crystallize.com/earth-landing-project/catalogue",
  }),
})

const query = graphql`
  query MyQuery {
    crystallize {
      catalogue {
        id
        children {
          path
          ... on Crystallize_Product {
            id
            defaultVariant {
              images {
                url
              }
            }
            localDefaultImage {
              childImageSharp {
                  gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`

const Items = styled.div`
  background-color: #f6f2ee;
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    display: block;
    width: 100%;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    display: block;
    width: 100%;
  }
  @media screen and (orientation: landscape) {
    display: grid;
    width: 100%;
    grid-template-columns: 26vh 26vh 26vh;
    grid-auto-rows: 40vh;
    grid-auto-columns: auto;
    justify-content: space-evenly;
    grid-auto-flow: row;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    display: grid;
    width: 100%;
    grid-template-columns: 19vh 19vh 19vh;
    grid-auto-rows: 40vh;
    grid-auto-columns: auto;
    justify-content: space-evenly;
    grid-auto-flow: row;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    display: grid;
    width: 100%;
    grid-template-columns: 24vh 24vh 24vh;
    grid-auto-rows: 45vh;
    grid-auto-columns: auto;
    justify-content: space-evenly;
    grid-auto-flow: row;
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    display: grid;
    width: 100%;
    grid-template-columns: 31vh 31vh 31vh;
    grid-auto-rows: 50vh;
    grid-auto-columns: auto;
    justify-content: space-evenly;
    grid-auto-flow: row;
  }
`

const Collection = (): ReactElement => {
  const catalogue = useStaticQuery(query).crystallize.catalogue
  const {
    setWhichModal,
    modalEnum,
    whichModal,
    openModal,
    modalOpen,
  } = useContext(CartContext)
  const itemData = useRef({})
  const [getItem, { called, loading }] = useLazyQuery(itemModalQuery, {
    client: client,
    onCompleted: data => {
      itemData.current = data.catalogue
      setWhichModal(modalEnum.item)
      openModal()
    },
    fetchPolicy: "no-cache",
  })

  const handleItemClick = (productData: itemQueryResults): void => {
    getItem({
      variables: { path: productData.path },
    })
  }

  const itemModalData = {
    modalLayoutProps: {
      productData: itemData.current,
    },
    modalProps: {
      isOpen: modalOpen,
      beforeTransform: "translate(-20%)",
      appearTransform: "translate(0)",
      disappearTransform: "translate(20%)",
      style: {
        display: "flex",
        backgroundColors: ["#d4eaf0", "#ffd700", "#ffb4c3"],
        boxShadow: "0 0 5px 2px black",
        borderRadius: "7px",
        padding: "5px",
        paddingBottom: "30px",
        overflow: "auto",
        transition: "all 0.2s cubic-bezier(0.25, 0.5, 0.5, 0.9)",
        maxWidth600: {
          width: "90vw",
          height: "65vh",
        },
        minWidth600: {
          width: "90vw",
          height: "65vh",
        },
        orientationLandscape: {
          width: "50%",
          height: "80vh",
        },
        minWidth768: {
          width: "60vw",
          height: "85vh",
        },
        minWidth992: {
          width: "50vw",
          height: "85vh",
        },
        minWidth1100: {
          width: "45vw",
          height: "85vh",
        },
        minWidth1200: {
          width: "35vw",
          height: "85vh",
        },
        minWidth1300: {
          width: "35vw",
          height: "85vh",
        },
        minWidth1400: {
          width: "35vw",
          height: "85vh",
        },
        minWidth1600: {
          width: "35vw",
          height: "85vh",
        },
        minWidth1700: {
          width: "35vw",
          height: "85vh",
        },
        minWidth1800: {
          width: "35vw",
          height: "85vh",
        },
        minWidth2000: {
          width: "35vw",
          height: "85vh",
        },
      },
    },
    ModalLayoutFunction: function ModalLayoutFunction() {
      return <ItemModalLayout {...itemModalData.modalLayoutProps.productData} />
    },
  }
  const cartModalData = {
    modalLayoutProps: {},
    modalProps: {
      isOpen: modalOpen,
      beforeTransform: "translate(20%)",
      appearTransform: "translate(0)",
      disappearTransform: "translate(-20%)",
      style: {
        display: "flex",
        backgroundColors: ["#7FBD4F", "#7FBD4F", "#7FBD4F"],
        boxShadow: "0 0 5px 2px black",
        borderRadius: "7px",
        padding: "5px",
        paddingBottom: "30px",
        overflow: "auto",
        transition: "all 0.2s cubic-bezier(0.25, 0.5, 0.5, 0.9)",
        maxWidth600: {
          width: "90vw",
          height: "65vh",
        },
        minWidth600: {
          width: "90vw",
          height: "65vh",
        },
        orientationLandscape: {
          width: "50%",
          height: "80vh",
        },
        minWidth768: {
          width: "60vw",
          height: "85vh",
        },
        minWidth992: {
          width: "50vw",
          height: "80vh",
        },
        minWidth1100: {
          width: "55vw",
          height: "85vh",
        },
        minWidth1200: {
          width: "50vw",
          height: "85vh",
        },
        minWidth1300: {
          width: "50vw",
          height: "85vh",
        },
        minWidth1400: {
          width: "50vw",
          height: "85vh",
        },
        minWidth1600: {
          width: "46vw",
          height: "85vh",
        },
        minWidth1700: {
          width: "42vw",
          height: "85vh",
        },
        minWidth1800: {
          width: "39vw",
          height: "85vh",
        },
        minWidth2000: {
          width: "37vw",
          height: "85vh",
        },
      },
    },
    ModalLayoutFunction: function ModalLayoutFunction() {
      return <CartModalLayout {...cartModalData.modalLayoutProps.productData} />
    },
  }
  return (
    <ModalProvider backgroundComponent={FadingBackground}>
      <Items id="items">
        {catalogue.children.map((product: productData, ind: number) => {
          return (
            <Cell
              key={ind}
              index={ind}
              handleItemClick={handleItemClick}
              productData={product}
            />
          )
        })}
      </Items>

      <ModalWrapper
        {...(whichModal === modalEnum.item
          ? itemModalData.modalProps
          : cartModalData.modalProps)}
      >
        {(whichModal === modalEnum.item
          ? itemModalData.ModalLayoutFunction
          : cartModalData.ModalLayoutFunction)()}
      </ModalWrapper>
    </ModalProvider>
  )
}

export default Collection
