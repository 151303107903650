import React from "react"
// import makeCarousel from "react-reveal/makeCarousel"
// import Slide from "react-reveal/Slide"
import styled from "styled-components"
// import Image from "@crystallize/react-image"
import { Carousel } from "react-responsive-carousel"

// const Container = styled.div`
//   position: relative;
//   width: 100%;
//   display: block;
//   justify-content: center;
//   align-items: center;
//   /* Extra small devices (phones, 600px and down) */
//   @media only screen and (max-width: 600px) {
//     height: 50vh;
//   }

//   /* Small devices (portrait tablets and large phones, 600px and up) */
//   @media only screen and (min-width: 600px) {
//     height: 50vh;
//   }

//   /* Medium devices (landscape tablets, 768px and up) */
//   @media only screen and (min-width: 768px) {
//     height: 50vh;
//   }

//   /* Large devices (laptops/desktops, 992px and up) */
//   @media only screen and (min-width: 992px) {
//     height: 50vh;
//   }

//   @media only screen and (min-width: 1100px) {
//     height: 50vh;
//   }

//   /* Extra large devices (large laptops and desktops, 1200px and up) */
//   @media only screen and (min-width: 1200px) {
//     height: 50vh;
//   }
// `
// const SlideContainer = styled.div`
//   @media only screen and (max-width: 600px) {
//     height: 50vh;
//   }

//   /* Small devices (portrait tablets and large phones, 600px and up) */
//   @media only screen and (min-width: 600px) {
//     height: 50vh;
//   }

//   /* Medium devices (landscape tablets, 768px and up) */
//   @media only screen and (min-width: 768px) {
//     height: 50vh;
//   }

//   /* Large devices (laptops/desktops, 992px and up) */
//   @media only screen and (min-width: 992px) {
//     height: 50vh;
//   }

//   @media only screen and (min-width: 1100px) {
//     height: 50vh;
//   }

//   /* Extra large devices (large laptops and desktops, 1200px and up) */
//   @media only screen and (min-width: 1200px) {
//     height: 50vh;
//   }
// `

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media only screen and (max-width: 600px) {
    height: 50vh;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    height: 50vh;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    height: 50vh;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    height: 50vh;
  }

  @media only screen and (min-width: 1100px) {
    height: 50vh;
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    height: 50vh;
    // width:auto;
  }
  @media only screen and (orientation: landscape) {
    height: 50vh;
  }
`

const Reel = ({ photosData }: reelProps): typeof Carousel => {

  photosData = photosData.filter(cur => {
    return cur
  })

  photosData = photosData.map((photo: image, ind: number) => {
    return (
      <ImageContainer key={ind}>
        <img src={photo.url} style={{ width: "30vh" }} alt={""} />
      </ImageContainer>
    )
  })

  return <Carousel>{photosData}</Carousel>
}

export default Reel
