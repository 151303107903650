import React, { useState, useContext, ReactElement } from "react"
import { CartContext } from "../contexts/CartContext"
import styled from 'styled-components';
import { lookup } from "node:dns";
const Price = styled.div`
font-size: 20px;
padding-top: 10px;
min-height: 50px;
font-family: 'Open Sans', sans-serif;
letter-spacing: -2px;
`;

const MultiItem = ({
  name,
  variants,
  whichButton,
  lookUpItem,
}: multiItemProps): ReactElement => {
  const { addProduct, closeModal } = useContext(CartContext)
  const [selected, setSelected] = useState("DEFAULT")
  // console.log(variants)
  const handleChange = (event: React.FocusEvent<HTMLSelectElement>) => {
    event.preventDefault()
    setSelected(event.target.value)
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    let foundProduct = variants.find(variant => variant.sku ===selected);
    foundProduct.masterName =  name;
    addProduct(foundProduct)
    closeModal()
  }

  return (
    <div>
      <Price>
        {(()=>{
          const item = lookUpItem(selected);
          if(!item || !item.price){
            return "";
          }else {
            return "$" + item.price;
          }
        })()
        }

      </Price>
      <form onSubmit={handleSubmit} style={{fontFamily:"Arial, Helvetica, sans-serif",
      fontSize:"1em"
    }}>
        <select
          onChange={handleChange}
          onBlur={handleChange}
          defaultValue={"DEFAULT"}
          style={{fontFamily:"Arial, Helvetica, sans-serif",fontSize:"1em",borderRadius:'4px'}}
        >
          <option value={"DEFAULT"} key={0}
          style={{fontFamily:"Arial, Helvetica, sans-serif"}}
          >
            Select
          </option>
          {variants.map((variant, ind) => {
            return (
              <option key={ind + 1} value={variant.sku}>
                {variant.attributes.reduce(
                  (acc, attribute) => acc + attribute.value + " ",
                  ""
                )}
              </option>
            )
          })}
        </select><br />
        {whichButton(selected)}
      </form>
    </div>
  )
}

export default MultiItem
