import React, { ReactElement } from "react"
import {GatsbyImage,getImage} from "gatsby-plugin-image"
import styled from "styled-components"
interface galleryItemProps {
  key: number
  index: number
  handleItemClick: (productData: itemQueryResults) => void
  productData: itemQueryResults
}

const Item = styled.div``

const Cell = ({
  index,
  productData,
  handleItemClick,
}: galleryItemProps): ReactElement => {
  return (
    <div>
      <Item
        role="button"
        style={{
          width: "100%",
          height: "auto",
          cursor: "pointer",
        }}
        onClick={e => {
          e.preventDefault()
          handleItemClick(productData)
        }}
        onKeyDown={e => {
          e.preventDefault
          handleItemClick(productData)
        }}
        tabIndex={index}
      >
        <GatsbyImage
        // src={productData.localDefaultImage.childImageSharp.gatsbyImageData}
        alt={"Image of Item"}
          image={productData.localDefaultImage.childImageSharp.gatsbyImageData}
          // durationFadeIn={100}
          // imgStyle={{
          //   width: "100%",
          //   height: "100%",
          // }}
        />
      </Item>
    </div>
  )
}

export default Cell
