import React from "react"
import { USStates, CAStates } from "./States"
import styled from "styled-components"
import { useFormikContext } from "formik";
const Error = styled.div`
  color: red;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
`

const Select = styled.select`
background-color:#f6f2ee;
border-radius:3px;
border:2px;
width:100%;
-webkit-box-sizing: border-box;
-moz-box-sizing: border-box;
     box-sizing: border-box;
`;


const Field = styled.input`
background-color:#f6f2ee;
border-radius:3px;
border:2px;
width:100%;
-webkit-box-sizing: border-box;
-moz-box-sizing: border-box;
     box-sizing: border-box;
`;

const BaseForm = (props) => {
  const {
    // values,
    //   touched,
    // errors,
    // handleChange,
    // handleBlur,
    // handleSubmit,
    setSubmitting,
    //   validateForm,
    isValid,
    isValidating,
    isSubmitting,
  } = props
  const {values,errors, touched, handleSubmit, handleChange, handleBlur} = useFormikContext()

  const CountryAndRegion = (
    ShippingOrBillingCountry: string,
    ShippingOrBillingRegion: string,
    CountryValue: string,
    RegionValue: string,
    handleChange: () => void,
    handleBlur: () => void
  ) => {
    const Regions = CountryValue === "US" ? USStates : CAStates
    return (
      <div>
        <Select
          id={ShippingOrBillingCountry}
          name={ShippingOrBillingCountry}
          onChange={handleChange}
          onBlur={handleBlur}
          value={CountryValue}
        >
          <option value={"US"}>United States</option>
          <option value={"CA"}>Canada</option>
        </Select>
        <br />
        <Select
          id={ShippingOrBillingRegion}
          name={ShippingOrBillingRegion}
          onChange={handleChange}
          onBlur={handleBlur}
          value={RegionValue}
        >
          {Regions.map((region, ind) => {
            return (
              <option key={ind} value={region.name}>
                {region.name}
              </option>
            )
          })}
        </Select>
      </div>
    )
  }

  return (
    <div>
      <form
        onSubmit={e => {
          e.preventDefault()
          handleSubmit()
          setSubmitting(false)
        }}
      >
        <div style={{fontFamily: 'Helvetica, Arial, sans-serif'}}>Shipping Address</div>
        <div>
          {errors.ShippingName ? <Error>{errors.ShippingName}</Error> : null}
          <Field
            id="ShippingName"
            name="ShippingName"
            type={"text"}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.ShippingName}
            placeholder={"Shipping Name"}
          />
          <br />
          {errors.PhoneNumber ? <Error>{errors.PhoneNumber}</Error> : null}
          <Field
            id="PhoneNumber"
            name="PhoneNumber"
            type={"text"}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.PhoneNumber}
            placeholder={"Phone Number"}
          />
          <br />
          {errors.Email ? <Error>{errors.Email}</Error> : null}
          <Field
            id="Email"
            name="Email"
            type={"text"}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.Email}
            placeholder={"Email"}
          />
          <br />
          {errors.ShippingAddress1 ? (
            <Error>{errors.ShippingAddress1}</Error>
          ) : null}
          <Field
            id="ShippingAddress1"
            name="ShippingAddress1"
            type={"text"}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.ShippingAddress1}
            placeholder={"Address Line 1"}
          />
          <br />
          {errors.ShippingAddress2 ? (
            <Error>{errors.ShippingAddress2}</Error>
          ) : null}
          <Field
            id="ShippingAddress2"
            name="ShippingAddress2"
            type={"text"}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.ShippingAddress2}
            placeholder={"Address Line 2"}
          />
          <br />
          {CountryAndRegion(
            "ShippingCountry",
            "ShippingRegion",
            values.ShippingCountry,
            values.ShippingRegion,
            handleChange,
            handleBlur
          )}
          {errors.ShippingCity ? <Error>{errors.ShippingCity}</Error> : null}
          <Field
            id="ShippingCity"
            name="ShippingCity"
            type={"text"}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.ShippingCity}
            placeholder={"City"}
          />
          <br />
          {errors.ShippingPostalCode ? (
            <Error>{errors.ShippingPostalCode}</Error>
          ) : null}
          <Field
            id="ShippingPostalCode"
            name="ShippingPostalCode"
            type={"text"}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.ShippingPostalCode}
            placeholder={"PostalCode"}
          />
          <br />
        </div>
        <div style={{fontFamily: 'Helvetica, Arial, sans-serif'}}>Billing Address</div>
        <div>
          {errors.BillingName ? <Error>{errors.BillingName}</Error> : null}
          <Field
            id="BillingName"
            name="BillingName"
            type={"text"}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.BillingName}
            placeholder={"Name On Card"}
          />
          <br />
          {errors.BillingAddress1 ? (
            <Error>{errors.BillingAddress2}</Error>
          ) : null}
          <Field
            id="BillingAddres1"
            name="BillingAddress1"
            type={"text"}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.BillingAddress1}
            placeholder={"Address Line 1"}
          />
          <br />
          {errors.BillingAddress2 ? (
            <Error>{errors.BillingAddress2}</Error>
          ) : null}
          <Field
            id="BillingAddress2"
            name="BillingAddress2"
            type={"text"}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.BillingAddress2}
            placeholder={"Address Line 2"}
          />
          <br />
          {errors.BillingCity ? <Error>{errors.BillingCity}</Error> : null}
          <Field
            id="BillingCity"
            name="BillingCity"
            type={"text"}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.BillingCity}
            placeholder={"City"}
          />
          <br />
          {CountryAndRegion(
            "BillingCountry",
            "BillingRegion",
            values.BillingCountry,
            values.BillingRegion,
            handleChange,
            handleBlur
          )}
          {errors.BillingPostalCode ? (
            <Error>{errors.BillingPostalCode}</Error>
          ) : null}
          <Field
            id="BillingPostalCode"
            name="BillingPostalCode"
            type={"text"}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.BillingPostalCode}
            placeholder={"PostalCode"}
          />
          <br />
        </div>

      </form>
    </div>
  )
}

export default BaseForm
