import { gql } from "@apollo/client"

export const itemModalQuery = gql`
  query getItem($path: String!) {
    catalogue(language: "en", path: $path) {
      ... on Product {
        ...product
      }
    }
  }

  fragment component on Component {
    name
    content {
      ...singleLine
      ...richText
      ...imageContent
      ...paragraphCollection
      ...itemRelations
      ...gridRelations
      ...location
      ...propertiesTable
      ...dateTime
      ...videoContent
    }
  }

  fragment dateTime on DatetimeContent {
    datetime
  }

  fragment gridRelations on GridRelationsContent {
    grids {
      id
      name
    }
  }

  fragment imageContent on ImageContent {
    images {
      ...image
    }
  }

  fragment image on Image {
    url
    altText
    key
    variants {
      url
      width
      key
    }
  }

  fragment itemRelations on ItemRelationsContent {
    items {
      id
      name
      path
    }
  }

  fragment location on LocationContent {
    lat
    long
  }

  fragment paragraphCollection on ParagraphCollectionContent {
    paragraphs {
      title {
        ...singleLine
      }
      body {
        ...richText
      }
      images {
        ...image
      }
      videos {
        ...video
      }
    }
  }

  fragment product on Product {
    id
    name
    type
    path
    components {
      ...component
    }

    variants {
      id
      name
      sku
      price
      attributes {
        attribute
        value
      }
      priceVariants {
        identifier
        name
        price
        currency
      }
      stock
      isDefault
      images {
        url
        altText
        key

        variants {
          key
          width
          url
        }
      }
    }
  }

  fragment propertiesTable on PropertiesTableContent {
    sections {
      ... on PropertiesTableSection {
        title
        properties {
          key
          value
        }
      }
    }
  }

  fragment richText on RichTextContent {
    plainText
  }

  fragment singleLine on SingleLineContent {
    text
  }

  fragment videoContent on VideoContent {
    videos {
      ...video
    }
  }

  fragment video on Video {
    id
    playlists
    title
    thumbnails {
      ...image
    }
  }
`
