import React from "react"
import {
  Formik,
} from "formik"
import * as yup from "yup"
import "core-js/es6/promise"
import "core-js/es6/set"
import "core-js/es6/map"

const FormikForm = ({ completePayment, children}) => {
  const CheckoutSchema = yup.object().shape({
    ShippingName: yup.string().required("Required"),
    PhoneNumber: yup
      .number()
      .typeError("Not a valid Phone Number")
      .required("Required"),
    ShippingAddress1: yup.string().required("Required"),
    ShippingAddress2: yup.string(),
    ShippingCountry: yup.string().required("Required"),
    ShippingCity: yup.string().required("Required"),
    ShippingPostalCode: yup.string().required("Required"),
    Email: yup.string().email("Not an Email").required("Required"),
    BillingName: yup.string().required("Required"),
    BillingAddress1: yup.string().required("Required"),
    BillingAddress2: yup.string(),
    BillingCity: yup.string().required("Required"),
    BillingPostalCode: yup
      .string().required("Required"),
    BillingCountry: yup.string().required("Required"),
    BillingRegion: yup.string().required("Required"),
  })



  //     ShippingName: "Developer Developerson",
  //     PhoneNumber: "1111111111",
  //     ShippingAddress1: "100 Test Street",
  //     ShippingAddress2: "apartment 1",
  //     ShippingCountry: "US",
  //     ShippingRegion: "California",
  //     ShippingCity: "Los Angeles",
  //     ShippingPostalCode: "11100",
  //     Email: "asdf@gmail.com",
  //     BillingName: "Developer Developerson",
  //     BillingAddress1: "100 Test Street",
  //     BillingAddress2: "Apartment 1",
  //     BillingCity: "Los Angeles",
  //     BillingPostalCode: "11100",
  //     BillingCountry: "US",
  //     BillingRegion: "California",

  return <Formik
  onSubmit={(values)=>{
    completePayment(values)
  }}
  initialValues={{
     ShippingName: "",
      PhoneNumber:"",
      ShippingAddress1: "",
      ShippingAddress2: "",
      ShippingCountry: "US",
      ShippingRegion: "Alabama",
      ShippingCity: "",
      ShippingPostalCode: "",
      Email: "",
      BillingName: "",
      BillingAddress1: "",
      BillingAddress2: "",
      BillingCity: "",
      BillingPostalCode: "",
      BillingCountry: "US",
      BillingRegion: "Alabama",
      //   ShippingName: "Developer Developerson",
      // PhoneNumber: "1111111111",
      // ShippingAddress1: "100 Test Street",
      // ShippingAddress2: "apartment 1",
      // ShippingCountry: "US",
      // ShippingRegion: "California",
      // ShippingCity: "Los Angeles",
      // ShippingPostalCode: "11100",
      // Email: "asdf@gmail.com",
      // BillingName: "Developer Developerson",
      // BillingAddress1: "100 Test Street",
      // BillingAddress2: "Apartment 1",
      // BillingCity: "Los Angeles",
      // BillingPostalCode: "11100",
      // BillingCountry: "US",
      // BillingRegion: "California",
  }}
  validationSchema={CheckoutSchema}
  validateOnBlur={false}
  validateOnChange={false}
  >
    {children}
  </Formik>
}

export default FormikForm
