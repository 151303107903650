import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js"
import React, { useState, useContext } from "react"
import { CartContext } from "../contexts/CartContext"
import "core-js/es6/promise"
import "core-js/es6/set"
import "core-js/es6/map"
import FormikForm from "./FormikForm"
import CreditCardForm from "./CreditCardForm";
import { useFormikContext } from "formik"
import BaseForm from "./BaseForm"
import styled from 'styled-components'

import { API,Amplify } from 'aws-amplify';
// file generated by the sls plugin
import awsconfig from '../aws-exports';

Amplify.configure(awsconfig);

export const FormWidth = `
@media only screen and (max-width: 600px) {
  width: 100%;
}



/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  width: 100%;
}
@media screen and (orientation: landscape) {
  width: 100%;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  width: 70%;
  @media screen and (orientation: portrait) {
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  width: 70%;
  @media screen and (orientation: portrait) {
  }
}

@media only screen and (min-width: 1100px) {
  width: 65%;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  width: 65%;
}
@media only screen and (min-width: 1300px) {
  width: 60%;
}
@media only screen and (min-width: 1400px) {
  width: 60%;
}
@media only screen and (min-width: 1600px) {
  width: 60%;
}
@media only screen and (min-width: 1700px) {
  width: 50%;
}
@media only screen and (min-width: 1700px) {
  width: 50%;
}
@media only screen and (min-width: 1800px) {
  width: 50%;
}
@media only screen and (min-width: 2000px) {
  width: 50%;
}
`
const FormContainer=styled.div`
${FormWidth};
margin-left:auto;
margin-right:auto;
`;

const PaymentComponents = styled.div`
border-radius:4px;
width:100%;
`;

const Pay = styled.button`
width:100%;
height:25px;
border-radius: 0 0 4px 4px;
border: 0;
background-color:black;
color:#f6f2ee;
`;

const PayButton = () =>{
  const {submitForm} = useFormikContext();

  return <Pay  style={{ cursor: "pointer" }}
    onClick={submitForm}
  >
  Pay
</Pay>
}

export const CheckoutForm = ({toggleCheckoutCompleted, clearCart}) => {
  const [checkoutMessage, setCheckoutMessage] = useState("")
  const { cartItems } = useContext(CartContext)

  const skus = cartItems.map((item: cartItem) => {
    return item.sku
  })
  const stripe = useStripe()
  const elements = useElements()
  const completePayment = async values => {
    setCheckoutMessage("Processing")

   const fetchSession = async () => {
      const skusString = skus.join('&');
      const session = await API.post('ApiGatewayRestApi','/checkout',{responseType:'json',body:{
        skus:skusString
      },
      headers: {
        "Content-Type": "application/json"
      }
      })
      const { success, secret, reason, notInStock } = session
      if (success) {
        return secret
      } else if (!success) {
        if (reason === "notInStock") {
          setCheckoutMessage("One or more items no longer in stock.")
        }
      } else {
        setCheckoutMessage("Some issue checking out. Try again later.")
      }
    }
    await fetchSession().then(async secret => {
      const result = await stripe
        ?.confirmCardPayment(secret, {
          payment_method: {
            card: elements?.getElement(CardElement),
            billing_details: {
              name: values.BillingName,
              address: {
                city: values.BillingCity,
                country: values.BillingCountry,
                line1: values.BillingAddress1,
                line2: values.BillingAddress2,
                postal_code: values.BillingPostalCode,
                state: values.BillingRegion,
              },
            },
          },
          shipping: {
            name: values.ShippingName,
            phone: values.PhoneNumber,
            address: {
              line1: values.ShippingAddress1,
              city: values.ShippingCity,
              country: values.ShippingCountry,
              line2: values.ShippingAddress2,
              postal_code: values.ShippingPostalCode,
              state: values.ShippingRegion,
            },
          },
          receipt_email: values.Email,
        })
        .catch(() => {
          setCheckoutMessage(
            "Some issue with checkout. Please try again later."
          )
        })
      if (result.error) {
        setCheckoutMessage(`Payment Failed ${result?.error.message}`)
      } else if (
        result.paymentIntent &&
        result.paymentIntent.status === "succeeded"
      ) {
        
        clearCart()
        toggleCheckoutCompleted(true)
      } else {
        setCheckoutMessage(result?.error?.message)
      }
    })
  }

  return (
    <div>
      <FormikForm completePayment={completePayment} >
        <FormContainer>
          <BaseForm />
          <PaymentComponents>
            <CreditCardForm
              checkoutMessage={checkoutMessage}
              setCheckoutMessage={setCheckoutMessage}
            />
            <PayButton />
          </PaymentComponents>
        </FormContainer>
      </FormikForm>
      <div style={{ height:'5vh'}}/>
    </div>
  )
}


